import { Navigate, useRoutes } from 'react-router-dom';

import { generatePath, getViewAbsoluteUrl, views } from '~/core/views';
import { LeftMenu } from '~/shared/components/entities/left-menu';
import { PageLayout } from '~/shared/components/page-layout';

import { PasswordForm } from './password-form';
import { PersonalForm } from './personal-form';

import styles from './profile.module.styl';

const ProfileView:React.FC = () => {
  const routes = useRoutes([
    { path: views.profile.personal, element: <PersonalForm/> },
    { path: views.profile.password, element: <PasswordForm/> },
    { path: '*', element: <Navigate to={views.profile.personal as string} replace/> }
  ]);

 return (
    <PageLayout>
      <LeftMenu
        items={[
          { text: 'На главную', url: generatePath(getViewAbsoluteUrl('entities', 'list'), { entity: 'offers' }) },
          { text: 'Профиль', url: getViewAbsoluteUrl('profile', 'personal') },
          { text: 'Пароль', url: getViewAbsoluteUrl('profile', 'password') }
        ]}
        hideRoleEntities
      />
      <div className={styles.content}>
        <div className={styles.contentBody}>
          { routes }
        </div>
      </div>
    </PageLayout>
 );
};

export default ProfileView;
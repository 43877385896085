import { Delimeter } from '~/shared/components/delimeter';
import { UserForm } from '~/shared/components/users/form';
import { useUser } from '~/shared/hooks/use-user';

export const PersonalForm:React.FC = () => {
  const { user } = useUser();
  return (
    <>
      <h1>Профиль</h1>
      <Delimeter size="m"/>
      <UserForm initial={user || undefined}/>
    </>
  );
};
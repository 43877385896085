import { useNavigate } from 'react-router-dom';

import { IUserPassword } from '%/entities/user';
import { deflatObject } from '%/utils/flat-object';
import { passwordValidate } from '%/validation/password';
import { getViewAbsoluteUrl } from '~/core/views';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { Delimeter } from '~/shared/components/delimeter';
import { useNotification } from '~/shared/components/notification';
import { useUser } from '~/shared/hooks/use-user';
import { useUserActions } from '~/shared/hooks/use-user-actions';

const FORM_ID = 'updateUserPasswordProcess';

export const PasswordForm:React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { setCurrentUserPassword, updateCurrentUserPassword } = useUserActions();
  const { addSendingId, removeSendingId } = useNotification();

  const onSubmit = async (values:IUserPassword) => {
    addSendingId(FORM_ID);
    const action = user.hasPassword ? updateCurrentUserPassword : setCurrentUserPassword;
    const resp = await action(values);
    removeSendingId(FORM_ID);

    if (resp.errors) {
      return deflatObject(resp.errors);
    }

    if (!resp?.errorStatus) {
      navigate(getViewAbsoluteUrl('profile', 'personal'));
    }
  };

  return (
    <Form<IUserPassword>
      onSubmit={onSubmit}
      validate={passwordValidate}
      submitBtn="Сохранить"
      formId={FORM_ID}
    >
      { ({ errors }:IFormRenderProps<IUserPassword>) => (
        <>
          <h1>{user.hasPassword ? 'Изменить' : 'Установить'} пароль</h1>
          <Delimeter size="m"/>
          {user.hasPassword &&
          <>
            <FormRow>
              <Input
                label="Текущий пароль"
                name="password"
                type="password"
                errors={errors.password}
                password/>
              <div/>
            </FormRow>
            <Delimeter size="m"/>
          </>
          }
          <FormRow>
            <Input
              label={user.hasPassword ? 'Новый пароль' : 'Пароль'}
              name="newPassword"
              type="password"
              errors={errors.newPassword}
              password
              generatePassOn/>
            <Input
              label="Повторите пароль"
              name="retryPassword"
              type="password"
              errors={errors.retryPassword}
              password/>
          </FormRow>
        </>
      )}
    </Form>
  );
};